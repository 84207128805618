// Our Working process Style hear

.working-process-bg{
    background-image: url(../images/working-step/bg-01.jpg);
    max-width: 1680px;
    margin:0 auto ; 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

}


.rts-working-process-1{
    display: flex;
    flex-direction: column;
    align-items: center;
    .inner{
        width: 192px;
        height: 192px;
        border: 2px dashed #0b4df52f;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-bottom: 33px;
        transition: .3s;
        position: relative;
        &.two{
            &::after{
                content: '02';
            }
        }
        &.three{
            &::after{
                content: '03';
            }
        }
        &.four{
            &::after{
                content: '04';
            }
        }
        &::after{
            position: absolute;
            right: 5px;
            top: 7px;        
            content: '01';
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: var(--color-primary-2);
            color: #fff;
            transform: scale(0);
            transition: .3s;

        }
        .icon{
            height: 144px;
            width: 144px;
            background: #fff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .content{
        .title{
            margin-bottom: 7px;
        }
    }
    &.process-lg{
        .inner{
            width: 245px;
            height: 245px;
            &::after{
                width: 60px;
                height: 60px;
    
            }
            .icon{
                width: 193.03px;
                height: 193.03px;
            }
        }
    }
    &:hover{
        .inner{
            border: 2px dashed var(--color-primary-2);
            &::after{
                transform: scale(1);
            }
        }
    }
}


.rts-service-areah2-im-3{
    .image-area{
        position: relative;
        margin-bottom: 120px;
        margin-top: 30px;
        @media #{$small-mobile} {
            margin-bottom: 80px;
        }
        .ratio-area{
            position: absolute;
            top: 60px;
            left: 60px;
            @media #{$small-mobile} {
                top: 30px;
                left: 30px;
            }
            .ratio{
                color: #fff;
                margin-bottom: 0px;
                font-size: 40px;
                font-weight: 700;
            }
            span{
                font-size: 18px;
                font-weight: 500;
                color: #fff;
                @media #{$small-mobile} {
                    font-size: 16px;
                }
            }
        }
        img{
            border-radius: 20px;
        }
        img{
            &.two{
                position: absolute;
                z-index: 5;
                top: -10%;
                left: 40%;
                animation: jump-1 5s linear infinite;
            }
        }
        img{
            &.three{
                position: absolute;
                left: 18%;
                top: 38%;
                @media #{$laptop-device} {
                    width: 85%;
                }
            }
        }
    }
    &.eight{
        .title-area.service-h2.service::after{
            content: '01';
        }
    }
}
.working-process-area2{
    background: url("../images/working-step/bg-02.jpg");
    max-width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    .container{
        .title-area{
            span{
                color: #fff;
                font-size: 18px;
                font-weight: 500;
                line-height: 23px;
            }
            .title{
                color: #fff;
            }
        }
        .rts-working-process-1{
            .inner{
                border-color: #5264f330;
                &::after{
                    background: var(--color-primary-4);
                }
            }
            &:hover{
                .inner{
                    border-color: var(--color-primary-4);
                }
            }
            .content{
                .title{
                    color: #fff;
                }
            }
        }
    }
}


.working-process-area2{
    &.home-6{
        .rts-working-process-1{
            .inner{
                border-color: #fa360a25;
            }
        }
    }
}

.index-seven .rts-title-area.gallery::after {
    content: "Gallery";
    left: 18%;
    transform: translateX(-50%);
    z-index: -1;
    display: none;
}

.working-process-area2.home-6 .container .rts-working-process-1:hover .inner {
    border-color: var(--color-primary-6);
}
.working-process-area2.home-6 .container .rts-working-process-1 .inner::after{
    background: var(--color-primary-6);
}
.rts-working-process-section{
    &.eight{
        .title-area{
            &::after{
                content: '02';
                left: 7%;
                transform: translateX(-50%);
                top: -70%;
                height: 145px;
            }
        }
        .rts-working-process-inner{
            margin-top: 60px;
            .wrapper{
                text-align: center;
                padding: 25px 40px 15px;
                background-color: #fff;
                border-style: solid;
                border-width: 1px;
                border-color: #e4e7ee;
                border-radius: 20px;
                box-shadow: 0px 12px 24px 0px rgb(18 22 34 / 7%);
                transition: all .4s;
                @media(max-width:1200px){
                    margin-bottom: 15px;
                }
                &:hover{
                    .icon{
                        img{
                            transform: scale(110%);
                        }
                    }
                    .content{
                        .title{
                            color: var(--color-primary-2);
                        }
                    }
                }
                .icon{
                    img{    
                        width: 70px;
                        transform: scale(100%);
                        transition: all .4s;
                    }
                }
                .content{
                    margin-top: 25px;
                    transition: all .4s;
                    .title{
                        margin-bottom: 12px;
                        transition: all .4s;
                    }
                    .disc{
                        margin-bottom: 25px;
                        line-height: 1.8;
                    }
                }
            }
        }
    }
}

.bg-working-steps{
    background-image: url(../images/working-step/bg-03.jpg);
}

.single-steps-area-nine{
    text-align: center;
    .icon-area{
        height: 110px;
        width: 110px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        background: #FFFFFF;
        margin-bottom: 70px;
        position: relative;
        @media #{$md-layout} {
            height: 91px;
            width: 91px; 
        }
        @media #{$sm-layout} {
            height: 91px;
            width: 91px;
        }
        &::after{
            position: absolute;
            content: '';
            left: 50%;
            bottom: -10px;
            width: 19px;
            transform: translateX(-50%);
            height: 19px;
            background-image: url(../images/working-step/01.png);
        }
    }
    .body{
        padding: 54px 33px 50px 33px;
        text-align: center;
        border-radius: 20px;
        border: 1px solid #EFEEEE;
        background: #FFF;
        box-shadow: 0px 19px 51px 0px rgba(14, 18, 30, 0.06);
        position: relative;
        @media #{$md-layout} {
            padding: 44px 14px 44px 14px;
        }
        @media #{$sm-layout} {
            padding: 44px 14px 44px 14px;
        }
        span{
            position: absolute;
            height: 50px;
            width: 50px;
            background: #fff;
            border: 1px solid #EFEEEE;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            left: 50%;
            transform: translateX(-50%);
            top: -30px;
            z-index: 1;
            color: var(--color-primary-7);
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            transition: .3s;
            &::after{
                position: absolute;
                content: '';
                background: rgba(229, 125, 97, 0.10);
                width: 32px;
                height: 32px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: -1;
                border-radius: 50%;
                transition: .3s;
            }
        }
        .title{
            color: #212429;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            @media #{$md-layout} {
                font-size: 16px;
            }
        }
    }
    &:hover{
        .body{
            span{
                color: #fff;
                &::after{
                    background: var(--color-primary-7);
                }
            }
        }
    }
}

.rts-progress-ten-wrapper{
    display: flex;
    flex-direction: column;
    gap: 30px;
    .single-progress-area-10{
        .progress-top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .name{
                margin-bottom: 0;
                color: #161921;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
            }
            .parcent{
                color: #161921;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
            }
        }
    }
    .progress{
        height: 5px !important;
        position: relative;
        width: 100%;
        margin-top: 14px;
        border-radius: 0;
        background: #E3E3E3;
        .bg--primary{
            background: var(--color-primary-2);
            border-radius: 0;
            border: none;
        }
        &::after{
            display: none;
        }
    }
    .progress-bar{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }
}

.experience-ten-area-left{
    padding-right: 50px;
    .title-area-left-ten{
        .pre-title{
            color: #016678;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        .title{
            color: #1C2539;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: 58px;
            margin-bottom: 50px;
            margin-top: 7px;
            @media #{$smlg-device} {
                font-size: 36px;
                line-height: 42px;
            }
            @media #{$sm-layout} {
                br{
                    display: none;
                }
            }
            @media #{$large-mobile} {
                font-size: 32px;
                line-height: 42px;
                margin-bottom: 30px;
            }
            span{
                color: #016678;
                font-size: 48px;
                font-style: normal;
                font-weight: 400;
                line-height: 58px;
                @media #{$smlg-device} {
                    font-size: 36px;
                    line-height: 42px;
                }
                @media #{$large-mobile} {
                    font-size: 32px;
                    line-height: 42px;
                }
            }
        }
        p.disc{
            margin-bottom: 40px;
            max-width: 80%;
            @media #{$large-mobile} {
                max-width: 100%;
            }
        }
    }
}

.bg-experience-10{
    background: #F6F8FF;
}



.experience-ten-area-right{
    padding-left: 50px;
    @media #{$md-layout} {
        padding-left: 10px;
        margin-top: 50px;
    }
    @media #{$sm-layout} {
        padding-left: 0;
        margin-top: 30px;
    }
    .single-experience-ten-list{
        display: flex;
        align-items: center;
        gap: 40px;
        margin-bottom: 40px;
        @media #{$large-mobile} {
            flex-direction: column;
            align-items: flex-start;
        }
        .icon{
            min-width: 90px;
            height: 90px;
            border: 1px solid #DDE2F1;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .information{
            .title{
                margin-bottom: 12px;
            }
            p.disc{
                max-width: 80%;
            }
        }
    }
}

.home-blue.home-ten{
    .title-area::after{
        display: none;
    }
    .bg_testimonials-h2 .title-area span{
        color: #fff !important;
    }
    .title-area .title{
        color: #fff !important;
    }
}

.why-choose-us-section-10{
    padding-bottom: 620px;
}
.mt-decrease{
    margin-top: -500px;
}
.title-area-left-ten.center{
    text-align: center;
    .pre-title{
        color: #016678;
    }
    .title{
        margin-top: 15px;
        span{
            color: #016678;
        }
    }
}

.title-area-left-ten{
    .pre-title{
        color: var(--color-primary-2);
    }
    .title{
        margin-top: 15px;
        span{
            color: var(--color-primary-2);
            font-weight: 400;
        }
    }
}